import React, { Component } from 'react';

class Footer extends Component {
  render() {
    return (
      <footer>

        <div className="row">
          <div className="twelve columns">
            <ul className="social-links">
              <li key="xing">
                <a
                  href="https://www.xing.com/profile/Deniss_Marinuks"
                  rel="noopener noreferrer"
                  target="_blank">
                  <i className="fa fa-xing" />
                </a>
              </li>
              <li key="upwork" id="upwork-footer">
                <a
                  href="https://www.upwork.com/freelancers/~01aaea59eccca81951"
                  rel="noopener noreferrer"
                  target="_blank">
                  <img src="images/upw.png" width="42" alt="" />
                </a>
              </li>
              <li key="linkedin">
                <a href="https://www.linkedin.com/in/deniss-marinuks-975340a4/"><i className="fa fa-linkedin"></i></a>
              </li>
            </ul>

            <ul className="copyright">
              <li>&copy; Copyright 2022 Deniss Marinuks</li>
            </ul>

          </div>
          <div id="go-top"><a className="smoothscroll" title="Back to Top" href="#home"><i className="icon-up-open"></i></a></div>
        </div>
      </footer>
    );
  }
}

export default Footer;
