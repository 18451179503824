import React, { Component } from 'react';

class Portfolio extends Component {
    render() {
        let certs = [
            {
                img: 'IBM_AI_Engineering.jpg',
                label: 'IBM AI Engineering'
            },
            {
                img: 'IBM_Data_Science.jpg',
                label: 'IBM Data Science'
            },
            {
                img: 'philosophy,_science_and_religion.jpg',
                label: 'Philosophy Science and Religion'
            },
            {
                img: 'UC-9CE6WBH6.jpg',
                label: 'iOS8 Swift Course'
            },
            {
                img: 'UC-BIVKXAE4.jpg',
                label: 'Learn Photoshop and Web Design'
            },
            {
                img: 'UC-XQPBISEB.jpg',
                label: 'Web Developer Course'
            },
            {
                img: 'UC-0d876d0a-a9c9-4dab-addc-65817df14d36_2.jpg',
                label: 'MySQL Developer Course'
            },
            {
                img: 'UC-281d5d2c-6a7e-4bce-be0c-e0e888d23537.jpg',
                label: 'React, Hooks, Router, Redux'
            },
            {
                img: 'UC-7e131ad4-16a9-430e-8b84-1f5041e97785.jpg',
                label: 'React and Django Full Stack'
            },
            {
                img: 'UC-3ea7dd73-02af-4139-b4c9-145b787258e1.jpg',
                label: 'Certified Kubernetes Administrator'
            },
            {
                img: 'UC-a059986b-1785-4cea-9d13-44495c954f64.jpg',
                label: 'Certified AWS Developer'
            },
            {
                img: 'Istio.jpg',
                label: 'Istio Service Mesh'
            },
            {
                img: 'linuxadmin.png',
                label: 'Linux System Administrator (LFCS)'
            },
            {
                img: 'HashiCorpVault.png',
                label: 'HashiCorp Vault'
            },
            {
                img: 'UC-7c201215-3a26-4c49-8214-1d79406f2821.jpg',
                label: 'Helm Master Course'
            },
            {
                img: 'ScrumMasterPSM1.png',
                label: 'Scrum Master I'
            }
        ];

        let certsDivs = certs.map((cert, i) => {
            const certImage = 'images/Certs/' + cert.img;
            return <div key={'cert-' + i} className="columns portfolio-item">
                <div className="item-wrap">
                    <a href={certImage} title="">
                        <img alt="" src={certImage} />
                    </a>
                    <div className="portfolio-label">{cert.label}</div>
                </div>
            </div>
        })


        return (
            <section id="portfolio">
                <div className="row">
                    <div className="twelve columns collapsed">
                        <h1>{this.props.lang === 'en' ? 'My certificates from online courses.' : 'Meine Zertifikate aus Online-Kursen.'}</h1>
                        <div id="portfolio-wrapper" className="bgrid-quarters s-bgrid-thirds cf">
                            {certsDivs}
                        </div>
                    </div>
                </div>
            </section>
        );
    }
}

export default Portfolio;
