import React, { Component } from 'react';

class Resume extends Component {
  render() {
    let present = this.props.lang === 'en' ? 'Present' : 'Aktuell';
    let year = this.props.lang === 'en' ? 'year' : 'Jahr';
    let years = this.props.lang === 'en' ? 'years' : 'Jahren';

    let jacobsText = "I am a full stack developer for multiple projects. I work with Django, React.js, Golang and" +
      " Python every day. I also designed and developed a CI / CD infrastructure with GitLab, Docker, Docker Swarm" +
      " and Ansible. We use Scrum as our projects management framework. My job includes weekly meetings with" +
      " teams from different universities and presentation of our projects as well as providing easy " +
      "to understand documentation.";

    let cpText = "My job was to develop and create various websites, scripts and tools for internal and public use." +
      " I worked with Golang, PHP, Bash scripts, Python, JavaScript and Node.js. This also included working with " +
      "databases such as MySQL, MSSQL, Postgres, MongoDB, InfluxDB, Redis. In addition, I have worked with Ansible," +
      " Docker, GoCD, Airflow, network configuration, firewall customizations, debugging networks with tcpdump" +
      " and wireshark.";

    let freeText = "During my freelance career, I work as a full-stack developer, system administrator " +
      "and DevOps engineer. This is a short list of languages and tools I've worked with recently: Golang, React.js," +
      " MinIO, Django, Python, PHP, Java, JavaScript, Swift, Github, Gitlab, Airtable, Jira, Figma, Docker, " +
      "LXC, MySQL, PostgreSQL, MongoDB, Redis , WordPress, Cloudflare, DigitalOcean, Postfix, Rsnapshots.";

    let frTitle = "Safety and Emergency Procedures Instructor, Purser";
    let frText = "My duties were to train new cabin crew and pilots. I also worked as a purser. This included managing" +
      " crew and passengers, defusing conflict situations, dealing with medical emergencies, and ensuring that " +
      "all safety standards were met. As well as providing good customer service to the passengers.";

    let apprentice = "I always had a passion for programming, and computers, so finally I decided to switch my career" +
      " and follow my passion. I got an apprenticeship position at Comp-Pro. The education process included " +
      "working for 3 weeks and going to school for 1 week every month.";

    let coursesText = "I do a lot of online courses to learn new things and stay up to date with the quickly changing" +
      " IT world. This is a list of IT courses that I have completed:";

    let jacobsTextMini = "I joined Netlution company as an IT-Consultant and continued to work 10 hours per week with" +
      " Jacobs University."

    let netlution = "Setup up of the infrastructure in AWS using CloudFormation. Creating VPCs, Subnets, SecurityGroups," +
      " Peering connections, and IAMs. Automatizing software releases using ECS, ECR, CodeCommit, and pipelines. " +
      " Creating custom bash scripts to help with automatization processes. Developing a tool, written in Golang, to automatically" +
      " install and configure Hana and Ase database clusters in multiple nodes. Using gRPC framework for inter-process communications." +
      " Configuration of the Kubernetes cluster according to the latest security standards, using gatekeeper for pod security," +
      " updating and configuring helm charts for existing applications. Updating terraform scripts."

    let gfbio = "I support GFBio e.V team as a software developer, consultant, and with system integrations. My daily working tools are GitLab / CICD, Python, Go, OpenStack, React, Angular."

    let triumphAdler = "My daily tasks and tools include: management and security of multiple k8s clusters, Istio, Gatekeeper, Terraform, Exoscale, Golang, Angular, BitBucket / CICD, DevOps, GitOps, ArgoCD, Auth0."

    if (this.props.lang === 'de') {
      netlution = "Einrichten der Infrastruktur in AWS mit CloudFormation.  Erstellen von VPCs, Subnetzen, SecurityGroups, Peering-Verbindungen und IAMs. Automatisieren von Software-Releases mit ECS, ECR, CodeCommit und Pipelines. Erstellen von individuellen Bash-Skripten zur Unterstützung von Automatisierungsprozessen. " +

        "Entwicklung eines in Golang geschriebenen Tools zur automatischen Installation und Konfiguration von Hana- und Ase-Datenbank-Clustern auf mehreren Knoten. Verwendung des gRPC-Frameworks für die Kommunikation zwischen Prozessen. " +

        "Konfiguration des Kubernetes-Clusters nach den neuesten Sicherheitsstandards, Verwendung von Gatekeeper für die Pod-Sicherheit, Aktualisierung und Konfiguration von Helm-Charts für bestehende Anwendungen. " +

        "Aktualisierung der Terraform-Skripte."

      jacobsText = "Ich bin ein Full-Stack-Entwickler für mehrere Projekte. Ich arbeite täglich mit Django, React.js, " +
        "Golang und Python. Außerdem habe ich eine CI/CD-Infrastruktur mit GitLab, Docker, " +
        "Docker Swarm und Ansible entworfen und entwickelt. Wir verwenden Scrum-Framework für unsere Projekte. Zu " +
        "meinen Aufgaben gehören wöchentliche Treffen mit Teams aus verschiedenen Universitäten und " +
        "die Präsentation unserer Projekte sowie die Erstellung einer leicht verständlichen Dokumentation.";

      jacobsTextMini = "Ich begann bei Netlution als IT-Consultant und arbeitete weiterhin 10 Stunden pro Woche mit" +
        " Jacobs University."

      cpText = "Meine Aufgabe war die Entwicklung und Erstellung von verschiedenen Webseiten, Skripten und Tools für" +
        " den internen und öffentlichen Gebrauch. Ich arbeitete mit Golang, PHP, Bash-Skripten, Python, Javascript " +
        "und Node.js. Dazu gehörte auch die Arbeit mit Datenbanken wie MySQL, MSSQL, Postgres, MongoDB, InfluxDB," +
        " Redis. Darüber hinaus habe ich mit Ansible, Docker, Netzwerkkonfiguration, Firewall-Anpassungen, Debugging" +
        " von Netzwerken mit tcpdump und wireshark gearbeitet."

      freeText = "Während meiner freiberuflichen Karriere arbeite ich als Full-Stack-Entwickler, Systemadministrator " +
        "und DevOps Engineer. Dies ist eine kurze Liste von Sprachen und Tools, mit denen ich in letzter Zeit " +
        "gearbeitet habe: Golang, React.js, MinIO, Django, Python, PHP, Java, JavaScript, Swift, Github, Gitlab," +
        " Airtable, Jira, Figma, Docker, LXC, MySQL, PostgreSQL, MongoDB, Redis , WordPress, Cloudflare, " +
        "DigitalOcean, Postfix, Rsnapshots.";

      frTitle = "Ausbilder für Flugbegleiter, Sicherheits-und Notfall-Verfahren";
      frText = "Meine Aufgaben waren die Ausbildung von neuem Kabinenpersonal und Piloten. Außerdem war ich als" +
        " Purser tätig. Dazu gehörte das Management von Crew und Passagieren, das Entschärfen von " +
        "Konfliktsituationen, der Umgang mit medizinischen Notfällen und das Sicherstellen, dass alle " +
        "Sicherheitsstandards eingehalten wurden.";

      apprentice = "Ich hatte schon immer eine Leidenschaft für das Programmieren und für Computer, " +
        "also entschied ich mich schließlich, meinen Beruf zu wechseln und meiner Leidenschaft zu folgen. " +
        "Ich bekam einen Ausbildungsplatz bei Comp-Pro. Der Ausbildungsprozess beinhaltete, dass ich 3 Wochen " +
        "arbeitete und jeden Monat 1 Woche zur Schule ging.";

      coursesText = "Ich mache viele Online-Kurse, um neue Dinge zu lernen und mit der sich schnell verändernden " +
        "IT-Welt auf dem Laufenden zu bleiben. Dies ist eine Liste von IT-Kursen, die ich absolviert habe:"

      gfbio = "Ich unterstütze das Team des GFBio e.V. als Softwareentwickler, Berater und bei Systemintegrationen. Meine täglichen Arbeitswerkzeuge sind GitLab / CICD, Python, Go, OpenStack, React, Angular."

      triumphAdler = "Meine täglichen Aufgaben und Tools umfassen: Verwaltung und Sicherheit mehrerer k8s-Cluster, Istio, Gatekeeper, Terraform, Exoscale, Golang, Angular, BitBucket / CICD, DevOps, GitOps, ArgoCD, Auth0."
    }

    return (
      <section id="resume">

        <div className="row work">

          <div className="three columns header-col">
            <h1><span>{this.props.lang === 'en' ? 'Work' : 'Berufserfahrung'}</span></h1>
          </div>

          <div className="nine columns main-col">

            <div key="triumphAdler"><h3>TA Triumph-Adler GmbH</h3>
              <p className="info">Senoir DevOps Platform Engineer<span>&bull;</span> <em className="date">12/2022 - {present}</em></p>
              <p>{triumphAdler}</p>
            </div>

            <div key="Jacobs"><h3>GFBio e.V.</h3>
              <p className="info">Software development and system integration<span>&bull;</span> <em className="date">11/2023 - {present}</em></p>
              <p>{gfbio}</p>
            </div>

            <div key="Netlution"><h3>Netlution</h3>
              <p className="info">IT Consultant<span>&bull;</span> <em className="date">10/2021 - 11/2022</em></p>
              <p>{netlution}</p>
            </div>

            <div key="Jacobs"><h3>Jacobs University Bremen</h3>
              <p className="info">Research Assistand IT Technician<span>&bull;</span> <em className="date">10/2021 - 10/2023</em></p>
              <p>{jacobsTextMini}</p>
            </div>

            <div key="Jacobs"><h3>Jacobs University Bremen</h3>
              <p className="info">Research Assistand IT Technician<span>&bull;</span> <em className="date">02/2020 - 09/2021</em></p>
              <p>{jacobsText}</p>
            </div>

            <div key="CP"><h3>Comp-Pro</h3>
              <p className="info">{this.props.lang === 'en' ? 'Software Developer' : 'Softwareentwickler'}<span>&bull;</span> <em className="date">08/2017 - 01/2020</em></p>
              <p>{cpText}</p>
            </div>

            <div key="Freelancer"><h3>{this.props.lang === 'en' ? 'Freelancing' : 'Freiberufler'}</h3>
              <p className="info">Freelancer<span>&bull;</span> <em className="date">01/2015 - {present}</em></p>
              <p>{freeText}</p>
            </div>

            <div key="Ryanair"><h3>Ryanair</h3>
              <p className="info">{frTitle}<span>&bull;</span> <em className="date">12/2006 - 08/2017</em></p>
              <p>{frText}</p>
            </div>

          </div>
        </div>

        <div className="row education">
          <div className="three columns header-col">
            <h1><span>{this.props.lang === 'en' ? 'Education' : 'Bildung'}</span></h1>
          </div>

          <div className="nine columns main-col">
            <div className="row item">
              <div className="twelve columns">

                <div key="Ausbildung"><h3>{
                  this.props.lang === 'en' ? 'Apprenticeship as IT Specialist Application Developer'
                    : 'Ausbildung zum Fachinformatiker Anwendungsentwickler'
                }</h3>
                  <p className="info">Comp-Pro <span>&bull;</span><em className="date">02/2017 - 01/2020</em></p>
                  <p>{apprentice}</p>
                </div>

                <div key="self-education"><h3>{this.props.lang === 'en' ? 'Self-education' : 'Selbstausbildung'}</h3>
                  <p className="info">{this.props.lang === 'en' ? 'Online courses' : 'Online-Kurse'}<span>&bull;</span><em className="date">01/2015 - {present}</em></p>
                  <p>{coursesText}</p>
                  <div className="twelve columns main-col flex no-pad-both wrap">

                    <div className="col-45">
                      <ul className="mylist">
                        <li>Linux System Administrator (LFCS)</li>
                        <li>Certified AWS Developer</li>
                        <li>Certified Kubernetes Administrator</li>
                        <li>Istio Service Mesh</li>
                        <li>HashiCorp Vault</li>
                        <li>Professional Scrum Master I</li>
                        <li>Web Developer Course, build 14 websites</li>
                        <li>React & Django Full Stack</li>
                        <li>The complete React Developer Course</li>
                      </ul>
                    </div>

                    <div className="col-45">
                      <ul className="mylist">
                        <li>Learn Photoshop and Web Design</li>
                        <li>IBM Data Science</li>
                        <li>IBM AI Engineering</li>
                        <li>iOS8 und Swift Course, build 15 apps</li>
                        <li>Andoird Developer Course, build 14 apps</li>
                        <li>Programming Java for Beginners</li>
                        <li>The Complete MySQL Developer Course</li>
                        <li>IPv6 in modern Networks</li>
                      </ul>
                    </div>

                  </div>
                </div>

              </div>
            </div>
          </div>
        </div>




        <div className="row skill">

          <div className="three columns header-col">
            <h1><span>{this.props.lang === 'en' ? 'Skills' : 'EDV-Kenntnisse'}</span></h1>
          </div>

          <div className="nine columns main-col flex no-pad">

            <div className="col-50">

              <div className="skill-header">
                <img src="/images/paint-brush-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">Design (6+ {years})</h4>
              </div>
              <ul className="skills-list">
                <li>Photoshop</li>
                <li>GNU Image Manipulation Program</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/desktop-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Web / Front-end' : 'Web / Frontend-Entwicklung'}</h4>
              </div>
              <ul className="skills-list">
                <li>HTML (6+ {years})</li>
                <li>CSS (6+ {years})</li>
                <li>React.js (4+ {years})</li>
                <li>Mithril.js (4+ {years})</li>
                <li>Bootstrap (4+ {years})</li>
                <li>Wordpress (2+ {years})</li>
                <li>Webpack</li>
                <li>SPA</li>
                <li>PWA</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/mobile-alt-solid.svg" alt="" className="icon-mobile" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Mobile development (2+ years)' : 'Mobile Entwicklung (2+ Jahren)'}</h4>
              </div>
              <ul className="skills-list">
                <li>Apple Swift / Xcode</li>
                <li>Android Java</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/hdd-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Virtualization technologies' : 'Virtualisierungstechnologien'}</h4>
              </div>
              <ul className="skills-list">
                <li>Docker, Swarm, Compose (4+ {years})</li>
                <li>LXD / LXC (4+ {years})</li>
                <li>Proxmox, OpenStack (2+ {year})</li>
                <li>VMWare (1+ {year})</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/code-branch-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">DevOps</h4>
              </div>
              <ul className="skills-list">
                <li>Bash Scripting (4+ {years})</li>
                <li>GitLab CI/CD (3+ {years})</li>
                <li>GitHub actions (2+ {year})</li>
                <li>Ansible (3+ {years})</li>
                <li>AWS (1+ {year})</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/key-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Encryption (4+ year)' : 'Verschlüsselung (4+ Jahren)'}</h4>
              </div>
              <ul className="skills-list">
                <li>OpenSSL</li>
                <li>Let's Encrypt</li>
                <li>GPG</li>
                <li>Self-signed certificates</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/ubuntu-brands.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Operating Systems' : 'Betriebssysteme'}</h4>
              </div>
              <ul className="skills-list">
                <li>Ubuntu</li>
                <li>Alpine</li>
                <li>Debian</li>
                <li>OpenBSD</li>
                <li>MacOS</li>
                <li>Windows</li>
              </ul>
              <p />

            </div>

            <div className="col-50">

              <div className="skill-header">
                <img src="/images/code-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Programming Languages' : 'Programmiersprachen'}</h4>
              </div>
              <ul className="skills-list">
                <li>Golang (5+ {years})</li>
                <li>Bash Scripting (4+ {years})</li>
                <li>Python (5+ {years})</li>
                <li>JavaScript (6+ {years})</li>
                <li>JAVA (2+ {years})</li>
                <li>PHP (2+ {years})</li>
                <li>Swift (1+ {year})</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/server-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Back-end' : 'Backend-Entwicklung'}</h4>
              </div>
              <ul className="skills-list">
                <li>Golang (Gin)</li>
                <li>Python (Flask, Django)</li>
                <li>Node.js (Express, Restify)</li>
                <li>PHP (Laravel)</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/database-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Databases' : 'Datenbanken'}</h4>
              </div>
              <ul className="skills-list">
                <li>MongoDB (5+ {years})</li>
                <li>PostgreSQL (4+ {years})</li>
                <li>MySQL (4+ {years})</li>
                <li>MSSQL (3+ {years})</li>
                <li>Redis (2+ {years})</li>
                <li>InfluxDB (1+ {years})</li>
                <li>ElasticSearch (1+ {year})</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/network-wired-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Network' : 'Netzwerkkenntnisse'}</h4>
              </div>
              <ul className="skills-list">
                <li>Apache, Nginx, Traefik (4+ {years})</li>
                <li>Shorewall, UFW, firewalld</li>
                <li>IP-Tables (3+ {years})</li>
                <li>NF-Tables (1+ {year})</li>
                <li>TCP-Dump, Wireshark</li>
                <li>OpenVPN, Wireguard</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/envelope-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">{this.props.lang === 'en' ? 'Mail' : 'Mail-Server'}</h4>
              </div>
              <ul className="skills-list">
                <li>Postfix</li>
                <li>Dovecot / Courier</li>
                <li>Sasl</li>
              </ul>
              <p />

              <div className="skill-header">
                <img src="/images/cogs-solid.svg" alt="" className="icon-brush" />
                <h4 className="skill-header__title">Frameworks / Tools</h4>
              </div>
              <ul className="skills-list">
                <li>Scrum</li>
                <li>Kanban</li>
                <li>Airtable</li>
                <li>Figma</li>
                <li>Cloudflare</li>
                <li>DigitalOcean</li>
                <li>Swagger</li>
                <li>GitHub / GitLab</li>
                <li>Material UI</li>
                <li>Jira</li>
              </ul>
              <p />

            </div>

          </div>
        </div>
      </section >
    );
  }
}

export default Resume;
