import React, { Component } from 'react';

class Contact extends Component {
   render() {
      return (
         <section id="contact">
            <div className="row section-head">
               <div className="two columns header-col">
                  <div className="mail-icon" />
               </div>

               <div className="ten columns">
                  <h1>{this.props.lang === 'en' ? 'Contact Me': 'Nehmen Sie Kontakt auf'}</h1>
                  <p className="lead">{this.props.lang === 'en' ?
                      'If you have any questions please use this form to contact me.' :
                      'Wenn Sie Fragen haben, nutzen Sie bitte dieses Formular, um mich zu kontaktieren.'}</p>
               </div>

            </div>

            <div className="row row-form">
               <div className="eight columns">

                  <form action="" method="post" id="contactForm" name="contactForm">
                     <fieldset>

                        <div>
                           <label htmlFor="contactName">Name <span className="required">*</span></label>
                           <input type="text" defaultValue="" size="35" id="contactName" name="contactName" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactEmail">Email <span className="required">*</span></label>
                           <input type="text" defaultValue="" size="35" id="contactEmail" name="contactEmail" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactSubject">Subject</label>
                           <input type="text" defaultValue="" size="35" id="contactSubject" name="contactSubject" onChange={this.handleChange} />
                        </div>

                        <div>
                           <label htmlFor="contactMessage">Message <span className="required">*</span></label>
                           <textarea cols="50" rows="15" id="contactMessage" name="contactMessage"></textarea>
                        </div>

                        <div>
                           <button className="submit">Submit</button>
                           <span id="image-loader">
                              <img alt="" src="images/loader.gif" />
                           </span>
                        </div>
                     </fieldset>
                  </form>

                  <div id="message-warning"> Error boy</div>
                  <div id="message-success">
                     <i className="fa fa-check"></i>Your message was sent, thank you!<br />
                  </div>
               </div>


               {/* <aside className="four columns footer-widgets">
                  <div className="widget widget_contact">

                     <h4>Address and Phone</h4>
                     <p className="address">
                        {name}<br />
                        {street} <br />
                        {city}, {state} {zip}<br />
                        <span>{phone}</span>
                     </p>
                  </div>
               </aside> */}
            </div>
         </section>
      );
   }
}

export default Contact;
