import React, { Component } from 'react';
import ParticlesBg from 'particles-bg';

class Header extends Component {
    componentDidMount() {
        document.getElementById('lang-select').addEventListener('click', e => {
            e.preventDefault();
            e.target.innerHTML = this.props.lang === 'en' ? 'EN' : 'DE';
            this.props.changeLang();
        })
    }
   render() {
       let about = this.props.lang === 'en' ? 'About' : 'Über mich';
       let resume = this.props.lang === 'en' ? 'Resume' : 'Lebenslauf';
       let testimonials = this.props.lang === 'en' ? 'Testimonials' : 'Referenzen';
       let contact = this.props.lang === 'en' ? 'Contact' : 'Kontakt';
       let cert = this.props.lang === 'en' ? 'Certificates' : 'Zertifikate';
       let networks = [];
       networks.push(
           <li key="xing"><a href="https://www.xing.com/profile/Deniss_Marinuks" rel="noopener noreferrer" target="_blank"><i className="fa fa-xing"></i></a></li>
       )
       networks.push(
           <li key="upwork" id="upwork"><a href="https://www.upwork.com/freelancers/~01aaea59eccca81951" rel="noopener noreferrer" target="_blank"><img src="images/upwh.png" width="42" alt="" /></a></li>
       )
       networks.push(
           <li key="linkedin"><a href="https://www.linkedin.com/in/deniss-marinuks-975340a4/"><i className="fa fa-linkedin"></i></a></li>
       )

      return (
         <header id="home">

             <ParticlesBg type="cobweb" bg={true} />

             <nav id="nav-wrap">

                 <a className="mobile-btn" href="#nav-wrap" title="Show navigation">Show navigation</a>
                 <a className="mobile-btn" href="#home" title="Hide navigation">Hide navigation</a>

                 <ul id="nav" className="nav">
                     <li className="current"><a className="smoothscroll" href="#home">Home</a></li>
                     <li><a className="smoothscroll" href="#about">{about}</a></li>
                     <li><a className="smoothscroll" href="#resume">{resume}</a></li>
                     <li><a className="smoothscroll" href="#portfolio">{cert}</a></li>
                     <li><a className="smoothscroll" href="#testimonials">{testimonials}</a></li>
                     <li><a className="smoothscroll" href="#contact">{contact}</a></li>
                     <li><a id="lang-select" href="#lang">DE</a></li>
                 </ul>
             </nav>

             <div className="row banner">
                 <div className="banner-text">
                     <h1 className="responsive-headline">{this.props.lang === 'en' ? "I'm Deniss Marinuks." : "Ich bin Deniss Marinuks."}</h1>
                     {
                         this.props.lang === 'en' ?
                             <h3>I'm a <span>Software Developer</span>. I love programming and I think everyone should try to learn something new everyday, because the world is full of amazing things.</h3>
                             :
                             <h3>Ich bin ein <span>Software-Entwickler</span>. Ich liebe das Programmieren und ich denke, jeder sollte versuchen, jeden Tag etwas Neues zu lernen, denn die Welt ist voller erstaunlicher Dinge.</h3>
                     }
                     <hr />
                     <ul className="social">
                         {networks}
                     </ul>
                 </div>
             </div>

             <p className="scrolldown">
                 <a className="smoothscroll" href="#about"><i className="icon-down-circle"></i></a>
             </p>

         </header>
      );
   }
}

export default Header;
