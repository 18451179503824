import React, { Component } from 'react';

class About extends Component {
   render() {
      let aboutMeText = "I am enthusiastic and passionate about programming and learning new things. I enjoy " +
          "solving complex problems, especially if I can create an elegant solution for that. Creating something " +
          "from nothing is also an amazing feeling. Having years of experience as a leader in my first job, I also " +
          "learned how to follow and be a better team member. I learned about teamwork, communication and conflict " +
          "resolutions. In my free time I keep learning new things, either with online courses or side projects. " +
          "I am also interested in Taoism/Zen/Buddhism. Body and mind have to be in balance. So meditation and " +
          "those principles help me to stay positive and mindful of other people's feelings and life in general.";
      if (this.props.lang === 'de') {
         aboutMeText = "Ich bin enthusiastisch und leidenschaftlich beim Programmieren und beim Lernen neuer Dinge. " +
             "Es macht mir Spaß, komplexe Probleme zu lösen, besonders wenn ich dafür eine elegante Lösung schaffen " +
             "kann. Etwas aus dem Nichts zu erschaffen ist auch ein tolles Gefühl. Durch meine jahrelange Erfahrung " +
             "als Führungskraft in meinem ersten Job habe ich auch gelernt, zu folgen und ein besseres Teammitglied " +
             "zu sein. Ich habe viel über Teamarbeit, Kommunikation und Konfliktlösungen gelernt. In meiner Freizeit" +
             " lerne ich ständig neue Dinge, entweder mit Online-Kursen oder Nebenprojekten. Ich interessiere mich " +
             "auch für Taoismus/Zen/Buddhismus. Körper und Geist müssen im Gleichgewicht sein. So helfen mir " +
             "Meditation und diese Prinzipien, positiv und achtsam gegenüber den Gefühlen anderer Menschen " +
             "und dem Leben im Allgemeinen zu bleiben.";
      }
      return (
         <section id="about">
            <div className="row">
               <div className="three columns">
                  <img className="profile-pic" src="/images/profilepic.jpg" alt="Tim Baker Profile Pic" />
               </div>
               <div className="nine columns main-col">
                  <h2>{this.props.lang === 'en' ? 'About Me' : 'Über Mich'}</h2>

                  <p>{aboutMeText}</p>
               </div>
            </div>

         </section>
      );
   }
}

export default About;
