import React, { Component } from 'react';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import About from './Components/About';
import Resume from './Components/Resume';
import Contact from './Components/Contact';
import Testimonials from './Components/Testimonials';
import Portfolio from './Components/Portfolio';

class App extends Component {

  constructor(props) {
    super(props);
    this.state = {lang:'en'};
  }

  changeLang = () => {
      this.setState(state => {
          return {lang:state.lang === 'en' ? 'de' : 'en'}
      })
  }

  render() {
    return (
      <div className="App">
        <Header lang={this.state.lang} changeLang={this.changeLang}/>
        <About lang={this.state.lang}/>
        <Resume lang={this.state.lang}/>
        <Portfolio lang={this.state.lang}/>
        <Testimonials lang={this.state.lang}/>
        <Contact lang={this.state.lang}/>
        <Footer lang={this.state.lang}/>
      </div>
    );
  }
}

export default App;
