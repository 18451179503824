import React, { Component } from 'react';

class Testimonials extends Component {
  render() {

    return (
      <section id="testimonials">
      <div className="text-container">
         <div className="row">

            <div className="two columns header-col">
               <h1><span>Client Testimonials</span></h1>
            </div>

            <div className="ten columns flex-container">
                  <ul className="slides">
                      <li key="up1">
                          <blockquote>
                              <p>Fantastic work, delivered on time and on budget.  I found Deniss honest,
                                  straightforward and creative.  He also gave me quite a lot of good ideas on his own
                                  volition, most of which we incorporated into the site.  He required no supervision,
                                  he just got on and did it.  100% recommendation.</p>
                              <cite>Upwork, full stack development and database management</cite>
                          </blockquote>
                      </li>
                      <li key="up2">
                          <blockquote>
                              <p>Awesome work, as always.  This guy is not to be missed, he's in the top 0.1% of people on Upwork for sure.</p>
                              <cite>Upwork, Website development</cite>
                          </blockquote>
                      </li>
                  </ul>
               </div>
            </div>
         </div>
   </section>
    );
  }
}

export default Testimonials;
